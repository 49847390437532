import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class CaseService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/cases?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/cases?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getCase(id) {
    return axios.get(API_URL + `admin/cases/${id}`, { headers: authHeader() });
  }

  saveCase(c, bus, tags, cl) {
    return axios.post(API_URL + `admin/cases/${c.id}`, { ...c, business_units: bus, tags: tags, client_logo: cl }, { headers: authHeader() });
  }

  async create(c) {
    const { data } = await axios.post(API_URL + `admin/cases/`, { ...c }, { headers: authHeader() });
    return data;
  }

  delete(id) {
    return axios.delete(API_URL + `admin/cases/${id}`, { headers: authHeader() });
  }
}

export default new CaseService();
