<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6 sm:col-span-6">
            <label for="published" class="block text-sm font-medium text-gray-700">
              Published?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.published ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="togglePublished">
                <span class="sr-only">Published</span>
                <span aria-hidden="true" :class="c.published ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="first_name" class="block text-sm font-medium text-gray-700">Project title</label>
            <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="last_name" class="block text-sm font-medium text-gray-700">Meta title</label>
            <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="result" class="block text-sm font-medium text-gray-700">Result</label>
            <input type="text" name="result" id="result" autocomplete="result" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.result">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="industry" class="block text-sm font-medium text-gray-700">Industry</label>
            <input type="text" name="industry" id="industry" autocomplete="industry" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.industry">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="email_address" class="block text-sm font-medium text-gray-700">Meta description</label>
            <input type="text" name="email_address" id="email_address" autocomplete="email" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.seo_description">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="email_address" class="block text-sm font-medium text-gray-700">Description</label>
            <TinyMceEditor v-model="c.description" model_name="Case" :model_id="c.id" :image="true" />
          </div>

          <div class="col-span-6 sm:col-span-6 lg:col-span-2">
            <label for="business_units" class="block text-sm font-medium text-gray-700">Business Unit</label>
            <BusinessUnitsSelect @triggerChange="setBusinessUnits" :multiple="true" :selection="business_units" />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="tags" class="block text-sm font-medium text-gray-700">Tags</label>
            <TagsSelect @triggerChange="setTags" :multiple="true" :selection="tags" />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="client_logo" class="block text-sm font-medium text-gray-700">Client logo</label>
            <ClientLogoSelect @triggerChange="setClientLogo" :selection="c.ClientLogo" />
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import CaseService from '../../../services/admin/cases/service';
import BusinessUnitsSelect from '../generic/BusinessUnitsSelect.vue';
import ClientLogoSelect from '../generic/ClientLogoSelect.vue';
import TagsSelect from '../generic/TagsSelect.vue';
import TinyMceEditor from '../generic/TinyMceEditor.vue';

export default {
  name: 'CaseFormComponent',
  props: ['case_item'],
  components: {
    BusinessUnitsSelect,
    ClientLogoSelect,
    TagsSelect,
    TinyMceEditor
  },
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.case_item,
      loading,
      business_units: props.case_item.BusinessUnits.map((bu)=>{return bu.id}),
      tags: props.case_item.Tags.map((t)=>{return t.id}),
      client_logo: props.case_item.ClientLogo ? props.case_item.ClientLogo.id:null
    }
  },
  methods: {
    setBusinessUnits(selected) {
      this.business_units = selected;
    },
    setTags(selected) {
      this.tags = selected;
    },
    setClientLogo(selected) {
      this.client_logo = selected;
    },
    togglePublished() {
      this.c.published = !this.c.published;
    },
    async handleSave() {
      this.loading = true;
      CaseService.saveCase(this.c, this.business_units, this.tags, this.client_logo);
      this.loading = false;
    }
  }
}
</script>
